import React from "react";
import './GenericNotFound.css';

function GenericNotFound() {
    return (
        <div className="notFoundPage">
            <h3>404. Page not found</h3>
        </div>
    );
}

export default GenericNotFound;